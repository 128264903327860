import { Helmet } from 'react-helmet'
import { TwitterFollowButton } from 'react-twitter-embed'

import ProfileImage from '../../../components/ProfileImage'
import verifiedBadge from '../../../assets/icons/verified.svg'

const teamMembers = [
  {
    username: '0xMick',
    profileImageUrl:
      'https://profile-images-02.heywallet.com/profile_images/1763610445754298368/0rjMkQGL_400x400.jpg',
    name: 'Crypto Mick',
    verified: true,
    url: 'https://twitter.com/0xMick',
  },
  {
    username: 'hey_aware',
    profileImageUrl:
      'https://profile-images-02.heywallet.com/profile_images/1480755070283120645/-PFGflCJ_400x400.jpg',
    name: 'Aware',
    verified: true,
    url: 'https://twitter.com/hey_aware',
  },
  {
    username: 'kiingsol',
    profileImageUrl:
      'https://profile-images-02.heywallet.com/profile_images/1757967940593348608/EYAYpwIA_400x400.jpg',
    name: 'King Sol',
    verified: true,
    url: 'https://twitter.com/kiingsol',
  },
  {
    username: 'artlogic',
    profileImageUrl:
      'https://profile-images-02.heywallet.com/profile_images/67290880/james_back_400x400.jpg',
    name: 'Artlogic',
    verified: true,
    url: 'https://twitter.com/artlogic',
  },
]

const About = () => {
  return (
    <div className="flex w-full h-full flex-col items-center pt-24 md:w-700px">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About - Hey Wallet</title>
        <meta
          name="description"
          content="Our team comes from AngelList, Product Hunt & DocuSign."
        />
      </Helmet>
      <div className="fade-focus-in mb-10">
        <h1 className="font-colfax mb-4 text-center text-46 font-light leading-none tracking-tight text-white">
          Our team comes from
        </h1>
        <h1 className="font-colfax mb-4 text-center text-56 font-black leading-none tracking-tight text-white">
          AngelList, DocuSign and Product Hunt
        </h1>
      </div>
      <div className="slide-in-blurred-top grid grid-cols-1 gap-x-8 gap-y-8 md:gap-x-14 md:grid-cols-2">
        {teamMembers.map((member) => (
          <div key={member.username} className="flex flex-col items-center">
            <div
              className="mb-3 w-160px overflow-hidden rounded-full"
              style={{
                minHeight: 160,
              }}
            >
              <ProfileImage src={member.profileImageUrl} width={160} />
            </div>
            {member.name && (
              <div className="flex items-center justify-center">
                <h2 className="font-colfax mr-3 text-3xl font-bold text-gray-50">{member.name}</h2>
                {member.verified && <img src={verifiedBadge} alt="verified" width="30" />}
              </div>
            )}
            <h3 className="font-colfax mt-1 text-center text-2xl font-light text-gray-969696">
              @{member.username}
            </h3>
            <div className="mt-2 mb-6">
              <TwitterFollowButton
                screenName={`${member.username}`}
                options={{
                  size: 'large',
                  showCount: false,
                  showScreenName: false,
                  dnt: true,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default About
